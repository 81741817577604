<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col lg="5">
        <iq-card class="p-4 top-box">
          <div class="text-center mb-4">
            <i class="las la-lock font-size-40 text-warning"></i>
            <h4>{{ $t('forgot.trouble') }}</h4>
          </div>
          <b-row class="d-flex justify-content-center">
            <b-col lg="9" cols="11">
              <validationObserver v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(send)" class="mb-4">
                  <b-row>
                    <b-col lg="12" class="mb-2 text-initial">
                      <label>{{ $t('forgot.label') }}</label>
                    </b-col>
                    <b-col lg="12" class="mb-2">
                      <input-form
                          v-model="email"
                          :placeholder="$t('forms.phone')"
                          :validate="'required'"
                          :name="$t('forms.phone')"
                      />
                    </b-col>
                    <b-col lg="12" class="mb-3">
                      <b-button variant="warning" type="submit" class="w-100">
                        <span class="font-size-16">{{ $t('forgot.send') }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validationObserver>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col lg="4" cols="2">
              <span class="social_login_border"></span>
            </b-col>
            <b-col lg="4" cols="7">
              <div class="d-flex justify-content-center">
                <span class="text-gray">{{ $t('forgot.or') }}</span>
              </div>
            </b-col>
            <b-col lg="4" cols="2">
              <span class="social_login_border" style="left: 0"></span>
            </b-col>
            <b-col lg="12">
              <div class="text-center font-size-18 p-3">
                <router-link :to="{name:'registration'}">
                  <span class="text-warning">{{ $t('forgot.new') }}</span></router-link>
              </div>
            </b-col>
          </b-row>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import authServices from '../auth.services'
export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    send () {
      authServices.forgetPassword({ email: this.email }).then(res => {
        core.showSnackbar('success', res.data.message)
      })
    }
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
@media(max-width:479px) {
  .social_login_border{
    width: 100%;
  }
}
</style>
